<template>
  <!-- Main content -->
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border content">
            <h2>Teachers</h2>
            <div class="box-tools pull-right">
              <button type="button" class="button" data-toggle="modal" data-target="#myModal">
                <span class="icon"><i class="mdi mdi-plus" /></span> <span>Add Teacher</span>
              </button>
            </div>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <div class="content">
              <h3>Teacher School Joining Code</h3><p>Give teachers at your school this code to enable them to automatically join this school when they sign up to EdShed.</p>
              <p v-if="!$store.state.user.school.code">
                <button class="button is-info" @click="generateSchoolCode">
                  Generate Code
                </button>
              </p>
              <div v-else>
                <p class="is-size-4">
                  School Joining Code: <span class="has-text-link" style="font-family: monospace;">{{ $store.state.user.school.code }}</span>
                </p>
                <p><b>WARNING: Do not share this code publically.</b></p>
                <p>
                  <button class="button is-danger" @click="removeSchoolCode">
                    Remove Code
                  </button>
                </p>
              </div>
            </div>
            <div v-if="canMergeUsers" id="canMergeUsersInfoBox">
              <div class="notification">
                <p><b>You have a mixture of <span v-if="numberWondeTeachers">({{ numberWondeTeachers }}) Wonde</span><span v-if="numberCleverTeachers">({{ numberCleverTeachers }}) Clever</span> and ({{ numberUnlinkedTeachers }}) unlinked teachers. Select two users to merge ({{ checkedRows.length }} selected).</b></p><p class="has-text-right" />
              </div>
              <p>
                <b-radio
                  v-model="wondeMergeRadio"
                  native-value="all"
                  @input="searchDidChange"
                >
                  All
                </b-radio>
                <b-radio
                  v-model="wondeMergeRadio"
                  native-value="unlinked"
                  @input="searchDidChange"
                >
                  Unlinked
                </b-radio>
                <b-radio
                  v-if="numberWondeTeachers"
                  v-model="wondeMergeRadio"
                  native-value="wonde"
                  @input="searchDidChange"
                >
                  Wonde
                </b-radio>
                <b-radio
                  v-if="numberCleverTeachers"
                  v-model="wondeMergeRadio"
                  native-value="clever"
                  @input="searchDidChange"
                >
                  Clever
                </b-radio>
              </p>
            </div>
            <button v-if="$store.state.user.school.admin && checkedRows.length == 2" type="button" class="button is-warning" :disabled="checkedRows.length != 2 ? 'disabled' : null" @click="showWondeMerge">
              Merge
            </button>
            <div style="margin-top:10px;">
              <b-field>
                <b-input
                  v-model="searchPhrase"
                  placeholder="Search..."
                  type="search"
                  icon-pack="fas"
                  icon="search"
                  @input="searchDidChange"
                />
              </b-field>
            </div>
            <b-table
              v-if="filteredTeacherData"
              :data.sync="filteredTeacherData"
              :paginated="true"
              :per-page="10"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :checked-rows.sync="checkedRows"
              checkable
              :sort="false"
              @check="didCheck"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="Name" sortable>
                  {{ props.row.name }}<br><router-link :to="'/users/' + props.row.username">
                    <small>@{{ props.row.username }}</small>
                  </router-link> | <a :href="'mailto:' + props.row.email"><small>{{ props.row.email }}</small></a>
                  <figure v-if="props.row.wonde_upi" class="figure is-pulled-right">
                    <img src="/img/wondesmall.png" style="width: 32px;">
                  </figure>
                  <figure v-if="props.row.clever_oid" class="figure is-pulled-right">
                    <img src="/img/clevericon.png" style="width: 32px;">
                  </figure>
                </b-table-column>
                <b-table-column field="admin" label="Admin">
                  <button v-if="props.row.admin" class="btn button is-success" type="submit" @click="setAdmin(props.row.id,false)">
                    Yes
                  </button>
                  <button v-else class="btn button" type="submit" @click="setAdmin(props.row.id,true)">
                    No
                  </button>
                </b-table-column>
                <b-table-column field="actions" label="">
                  <span class="is-pulled-right">
                    <button v-if="isAdmin" class="btn button is-link" @click="setTeacherPassword(props.row)">Set Password</button>
                    <button v-if="isAdmin" class="btn button is-danger" @click="deleteTeacher(props.row)">Delete</button>
                  </span>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon
                        custom-class="far"
                        pack="fa"
                        icon="frown"
                        size="is-large"
                      />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
            <div v-else class="alert">
              <b>{{ response }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <!-- Add Teacher Modal -->
    <div id="myModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document" style="margin: 10px auto">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 id="myModalLabel" class="modal-title">
              Add Teacher
            </h4>
          </div>
          <div class="modal-body">
            <form class="ui form loginForm form-horizontal" @submit.prevent="checkCreds">
              <input style="display:none"><input type="password" style="display:none">
              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('name')}">
                <label for="name" class="col-sm-2 control-label">Name*</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-user" /></span>
                  <input
                    v-model="name"
                    v-validate="'required'"
                    class="form-control"
                    name="name"
                    placeholder="Name"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('name')" class="help-block text-center">{{ errors.first('name') }}</span>
              </div>
              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('username')}">
                <label for="username" class="col-sm-2 control-label">Username*</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-users" /></span>
                  <input
                    v-model="username"
                    v-validate="'required|alpha_dash'"
                    class="form-control"
                    name="username"
                    placeholder="Username"
                    type="text"
                    autocomplete="off"
                    @input="checkUsername"
                  >
                  <span v-if="usernameAvailable" class="input-group-addon" style="background-color: hsl(141, 71%, 48%); color: white;"><i class="ion ion-ios-checkmark is-size-4" /></span>
                  <span v-else class="input-group-addon" style="background-color: hsl(348, 100%, 61%); color: white;"><i class="ion ion-ios-close is-size-4" /></span>
                </div>
                <span v-show="errors.has('username')" class="help-block text-center">{{ errors.first('username') }}</span>
              </div>
              <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('email')}">
                <label for="email" class="col-sm-2 control-label">Email*</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-envelope" /></span>
                  <input
                    v-model="email"
                    v-validate="'required|email'"
                    class="form-control"
                    name="email"
                    placeholder="Email"
                    type="text"
                    autocomplete="off"
                  >
                </div>
                <span v-show="errors.has('email')" class="help-block text-center">{{ errors.first('email') }}</span>
              </div>
              <div class="form-group col-sm-12">
                <label for="password" class="col-sm-2 control-label">Password</label>
                <!-- <div class="input-group">
                    <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                    <input class="form-control" name="password" placeholder="Password (leave blank to not change)" type="password" v-model="password" autocomplete="off">
                  </div> -->
                <password
                  v-model="password"
                  class="col-sm-10 has-text-centered"
                  style="max-width: inherit;"
                  :required="false"
                  @score="showScore"
                  @feedback="showFeedback"
                />
                <small v-for="fb in suggestions" v-if="!warning" class="notification col-sm-12 has-text-centered">💡{{ fb }}</small>
                <p v-if="warning" class="notification is-danger col-sm-12 has-text-centered">
                  ⚠️ {{ warning }}
                </p>
              </div>
              <!-- <div :class="{'form-group':true, 'col-sm-12':true, 'has-error': errors.has('password')}">

                <label for="password" class="col-sm-2 control-label">Password*</label>
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                  <input v-validate="'required|min:6'" class="form-control" name="password" placeholder="Password" type="password" v-model="password" autocomplete="off">
                </div>
                <span v-show="errors.has('password')" class="help-block text-center">{{ errors.first('password') }}</span>
              </div> -->
            </form>
            <div v-if="modalResponse != ''" class="alert alert-info">
              {{ modalResponse }}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn button" data-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn button is-link" :disabled="(!usernameAvailable || warning != '' || password.trim() == '' || score < 2)" @click="beforeAddTeacher">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <WondeMergeUsers v-if="showWondeMergeUsersList" :users="checkedRows" :pupils="false" @close="hideWondeMerge" @success="didMerge" />
  </section>
  <!-- /.content -->
</template>

<script>
import $ from 'jquery'
import { Validator } from 'vee-validate'
import Password from 'vue-password-strength-meter'
import WondeMergeUsers from '@/components/views/components/WondeMergeUsers'
import utils from '@/utils'
import { request } from '@/edshed-common/api'

export default {
  name: 'Teachers',
  components: {
    Password,
    WondeMergeUsers
  },
  data (router) {
    return {
      loading: '',
      teacherData: null,
      filteredTeacherData: null,
      response: 'Data Loading...',
      modalResponse: '',
      username: '',
      name: '',
      email: '',
      password: '',
      suggestions: [],
      warning: '',
      score: 0,
      checkedRows: [],
      showWondeMergeUsersList: false,
      searchPhrase: '',
      wondeMergeRadio: 'all',
      usernameAvailable: false
    }
  },
  computed: {
    isAdmin () {
      return (this.$store.state.user.school.admin === 1)
    },
    canMergeUsers () {
      if (this.teacherData) {
        const wps = this.numberWondeTeachers
        const cps = this.numberCleverTeachers
        const ps = this.numberUnlinkedTeachers
        console.log('wps: ' + wps)
        console.log('ps: ' + ps)
        if (ps && (wps || cps)) {
          return true
        }
        return false
      }
    },
    numberUnlinkedTeachers () {
      return this.teacherData.filter(p => (p.wonde_upi === null && p.clever_oid === null)).length
    },
    numberWondeTeachers () {
      return this.teacherData.filter(p => p.wonde_upi != null).length
    },
    numberCleverTeachers () {
      return this.teacherData.filter(p => p.clever_oid != null).length
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getListData()
    })
    const dictionary = {
      en_GB: {
        attributes: {
          name: 'Name',
          email: 'Email',
          username: 'Username',
          password: 'Password'
        }
      }
    }
    Validator.localize('en_GB', dictionary.en_GB)
  },
  methods: {
    checkUsername (e) {
      const username = e.target.value
      if (username.trim() === '' || username.trim().length < 4 || !utils.isAlphaNumericPunctuated(username.trim())) {
        this.usernameAvailable = false
        return
      }
      request('GET', 'users/exists/' + username, null)
        .then((response) => {
          const data = response.data
          this.usernameAvailable = !data.exists
        })
    },
    showWondeMerge () {
      this.showWondeMergeUsersList = true
    },
    hideWondeMerge () {
      this.showWondeMergeUsersList = false
    },
    didMerge () {
      this.hideWondeMerge()
      this.getListData()
    },
    didCheck () {
      this.$forceUpdate()
    },
    searchDidChange () {
      console.log(this.searchPhrase)
      if (this.searchPhrase !== '') {
        this.filteredTeacherData = []
        for (let i = 0; i < this.teacherData.length; i++) {
          // console.log(this.teacherData[i].name.toLowerCase())
          if (this.teacherData[i].name.toLowerCase().includes(this.searchPhrase.toLowerCase()) || this.teacherData[i].username.toLowerCase().includes(this.searchPhrase.toLowerCase()) || (this.teacherData[i].groups != null && this.teacherData[i].groups.toLowerCase().includes(this.searchPhrase.toLowerCase())) || (this.teacherData[i].upn && this.teacherData[i].upn.toLowerCase().includes(this.searchPhrase.toLowerCase())) || (this.teacherData[i].email && this.teacherData[i].email.toLowerCase().includes(this.searchPhrase.toLowerCase()))) {
            this.filteredTeacherData.push(this.teacherData[i])
          }
        }
      } else {
        this.filteredTeacherData = this.teacherData
      }
      if (this.canMergeUsers) {
        if (this.wondeMergeRadio === 'wonde') {
          this.filteredTeacherData = this.filteredTeacherData.filter(p => p.wonde_upi !== null)
        } else if (this.wondeMergeRadio === 'clever') {
          this.filteredTeacherData = this.filteredTeacherData.filter(p => p.clever_oid !== null)
        } else if (this.wondeMergeRadio === 'unlinked') {
          this.filteredTeacherData = this.filteredTeacherData.filter(p => (p.wonde_upi === null && p.clever_oid === null))
        }
      }
      this.$forceUpdate
    },
    showFeedback ({ suggestions, warning }) {
      console.log('🙏', suggestions)
      console.log('⚠', warning)
      this.suggestions = suggestions
      this.warning = warning
    },
    showScore (score) {
      console.log('💯', score)
      this.score = score
    },
    removeSchoolCode () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('DELETE', 'users/me/school/code', null)
        .then((response) => {
          const data = response.data

          if (data.error) {
            this.response = data.error
          } else {
            this.$store.commit('SET_USER', data.user)

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }
          }

          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    generateSchoolCode () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('POST', 'users/me/school/code', null)
        .then((response) => {
          const data = response.data

          if (data.error) {
            this.response = data.error
          } else {
            this.$store.commit('SET_USER', data.user)

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }
          }

          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    getListData () {
      request('GET', 'users/me/school/teachers', null)
        .then((response) => {
          const data = response.data
          this.teacherData = data.teachers

          // console.log('readerData: ' + this.readerData)
          if (!this.teacherData || this.teacherData.length === 0) {
            this.response = 'No Teachers'
          }
          this.searchDidChange()
        })
        .catch((error) => {
          console.log(error)

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    beforeAddTeacher () {
      if (this.password.trim().length < 7 || this.score < 2 || this.warning !== '') {
        alert('Secure password required.')
        return
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.addTeacher()
        }
      })
    },
    addTeacher () {
      console.log('add')
      const { name, username, email, password } = this

      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')

      const d = { name, username, email, password }

      /* Making API call to authenticate a user */
      request('POST', 'users/me/school/teachers', d)
        .then((response) => {
          this.toggleLoading()

          window.console.log('Response', response)
          const data = response.data
          if (data.error) {
            alert(data.error)
          } else {
            $('#myModal').hide().removeClass('in')
            $('.modal-backdrop').hide().remove()

            this.name = ''
            this.username = ''
            this.email = ''
            this.password = ''

            this.getListData()

            // console.log('readerData: ' + this.readerData)
            if (!this.teacherData || this.teacherData.length === 0) {
              this.response = 'No Teachers'
            }
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    deleteTeacher (teacher) {
      if (teacher.admin) {
        alert('Cannot delete admin teacher')
        return
      }
      if (teacher.id === this.$store.state.user.id) {
        alert('Cannot delete self')
        return
      }

      const conf = confirm('Are you sure you want to delete this teacher\'s account? This cannot be undone.')

      if (!conf) {
        return
      }

      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('DELETE', 'users/me/school/teachers/' + teacher.id, null)
        .then((response) => {
          const data = response.data
          this.getListData()

          // console.log('readerData: ' + this.readerData)
          if (!this.teacherData || this.teacherData.length === 0) {
            this.response = 'No Teachers'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    setTeacherPassword (teacher) {
      const p = prompt('Enter new password:')
      console.log(p)

      if (p) {
        this.toggleLoading()
        this.$store.commit('TOGGLE_LOADING')
        request('PUT', 'users/me/school/teachers/' + teacher.id, { password: p })
          .then((response) => {
          // console.log('readerData: ' + this.readerData)
            this.$buefy.toast.open({
              message: 'Success!',
              type: 'is-success'
            })
            this.toggleLoading()
            this.$store.commit('TOGGLE_LOADING')
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    },
    setAdmin (teacherId, admin) {
      if (!this.isAdmin) {
        alert('Must be admin.')
        return
      }
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request(admin ? 'put' : 'delete', 'users/me/school/teachers/' + teacherId + '/admin', null)
        .then((response) => {
          const data = response.data
          if (data.error) {
            alert(data.error)
          } else {
            this.getListData()
          }
          // console.log('readerData: ' + this.readerData)
          if (!this.teacherData || this.teacherData.length === 0) {
            this.response = 'No Teachers'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>
<style>
.modal-body form {
  display: inline-block;
}
</style>
