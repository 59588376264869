<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Merge {{ pupils ? 'Pupils' : 'Teachers' }}
        </p>
        <button class="delete" aria-label="close" @click="close" />
      </header>
      <section class="modal-card-body">
        <!-- Content ... -->
        <div class="columns">
          <div class="column is-5">
            <UserInfo v-if="userData" :data="userData[0]" :primary="left" />
          </div>
          <div class="column is-2 has-text-centered">
            <button class="button is-large" @click="toggleDirection">
              <i :class="{'ion':true, 'ion-ios-arrow-back':true, 'rotate':true, 'right': !left}" />
            </button>
          </div>
          <div class="column is-5">
            <UserInfo v-if="userData" :data="userData[1]" :primary="!left" />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" @click="doMerge">
          Merge
        </button>
        <button class="button">
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
import { request } from '@/edshed-common/api'
import UserInfo from '@/components/views/components/UserInfo'
export default {
  name: 'WondeMergeUsers',
  components: {
    UserInfo
  },
  props: {
    users: null,
    pupils: true
  },
  data (router) {
    return {
      userData: null,
      left: true,
      loadingScreen: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.userData = this.users.sort((a, b) => {
        if (a.wonde_upi === null && a.clever_oid === null) {
          return -1
        } else if (b.wonde_upi === null && b.clever_oid === null) {
          return 1
        } else if (a.wonde_upi) {
          return a.wonde_upi < b.wonde_upi ? -1 : 1
        } else if (a.clever_oid) {
          return a.clever_oid < b.clever_oid ? -1 : 1
        } else {
          return 0
        }
      })
    })
  },
  methods: {
    close () {
      this.$emit('close')
    },
    closeWithSuccess () {
      this.$emit('success')
    },
    toggleDirection () {
      this.left = !this.left
    },
    showLoadingScreen () {
      this.loadingScreen = this.$buefy.loading.open()
    },
    closeLoadingScreen () {
      this.loadingScreen.close()
    },
    doMerge () {
      const c = confirm('Are you sure you want to merge these users? Username @' + (this.left ? this.userData[0].username : this.userData[1].username) + ' and the associated pasword will be kept.')
      if (c) {
        this.showLoadingScreen()
        console.log('MERGE')
        let ep = 'users/me/school/pupils/' + this.userData[0].username + '/merge'
        if (!this.pupils) {
          ep = 'users/me/school/teachers/' + this.userData[0].username + '/merge'
        }
        request('POST', ep, { merge: this.userData[1].username })
          .then((response) => {
            this.closeLoadingScreen()
            const data = response.data
            console.log(data)
            this.closeWithSuccess()
          })
          .catch((error) => {
            console.log(error)
            this.closeLoadingScreen()
            if (error.response && error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    }
  }
}
</script>
<style scoped>
  .modal-card {
    width: 100%;
    margin: 0 20px;
  }
  .rotate {
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
  }
  .rotate.right {
      -moz-transform:rotate(180deg);
      -webkit-transform:rotate(180deg);
      transform:rotate(180deg);
  }
</style>
