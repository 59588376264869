<template>
  <div :id="'userInfo-' + data.id" :class="{'box':true, 'box-white':true, 'active':primary, 'inactive':!primary}">
    <!-- <textarea>{{data}}
    </textarea> -->
    <figure v-if="data.wonde_upi" class="figure is-pulled-right">
      <img src="/img/wondesmall.png" style="width: 32px;">
    </figure>
    <figure v-if="data.clever_oid" class="figure is-pulled-right">
      <img src="/img/clevericon.png" style="width: 32px;">
    </figure>
    <AvatarView v-if="data.avatar != null" :uniq="'userInfo-'+data.id" :data="(data.avatar && data.avatar != '') ? JSON.parse(data.avatar) : {}" scale-factor="0.5" />
    <p><b>{{ data.name }}</b> <small v-if="data.display_name">(<i class="fas fa-tv" /> {{ data.display_name }})</small></p>
    <p>@{{ data.username }}</p>
    <p v-if="data.email">
      Email: {{ data.email }}
    </p>
    <p v-if="data.upn">
      UPN: {{ data.upn || '' }}
    </p>
  </div>
</template>
<script>
import AvatarView from '@/components/views/AvatarView'
export default {
  name: 'UserInfo',
  components: {
    AvatarView
  },
  props: ['data', 'primary']
}
</script>
<style scoped>
  .inactive {
    opacity: 0.5;
    /* box-shadow: 0 0 0 1px hsl(348, 100%, 61%); */
  }
  .active {
    box-shadow: 0 0 0 3px hsl(141, 71%, 48%);
  }
</style>
